import React from "react"
import styled from "styled-components"
import { v4 } from 'uuid'
import { Link } from "gatsby"
import { graphql } from "gatsby"
import Img from 'gatsby-image'
import { Section, SectionDescription, SectionTitle, SectionLead, SectionHeader, SectionPageTitle, MainPanel, MarkdownContent, BreadCrumb } from "../components/section"
import Layout from "../components/layout"
import SEO from "../components/seo"
import MissionIcon from '../components/Icons/MissionIcon'
import VisionIcon from '../components/Icons/VisionIcon'
import BreakpointUp from "../components/Media/BreakpointUp"
import generateHTML from '../utils/generateHTML';
import Content, { HTMLContent } from '../components/Content';

const SectionCover = styled.div`  
  display: flex;
  flex-wrap:wrap;
  align-items: center;
  margin:0 -15px;   
`
const CoverFigure = styled.div`
  padding: 0 15px;
  width:100%;
  height: 100%;
  display:none;
  ${BreakpointUp.lg`   
    display:block;
    flex: 0 0 50%;
    max-width: 50%;
  `}
  .gatsby-image-wrapper {
    height: 100%;
    max-height: 360px;
    margin: 20px; 
    z-index: 1;
  }
`
const CoverImg = styled.div`
  position:relative;
  &::after{
    content:'';
    position:absolute;
    right:0;
    bottom:-20px;
    max-width:194px;
    max-height:194px;
    width:100%;
    height:100%;
    background-color:#0A4E82;
  }
`
const CoverContent = styled.div`
  position:relative;
  padding:0 15px;
  width:100%;
  ${BreakpointUp.lg`          
    flex: 0 0 50%;
    max-width: 50%;
  `}
  & p{
    margin-bottom:30px;
  }
`

const AboutGrid = styled.ul`
  display:flex;
  align-items: center;
  flex-wrap: wrap;
  margin:0 -15px 40px -15px;
  padding:0 0 40px;
  list-style:none;
  border-bottom:1px solid #EAEAEA;
  
  ${BreakpointUp.lg`   
    margin:0 -30px 70px -30px;
    padding:0 0 70px;
  `}
`
const AboutItemGrid = styled.li`
  position: relative;
  width: 100%;
  border:1px solid #EAEAEA;
  padding:30px;
  ${BreakpointUp.lg`   
    flex: 0 0 50%;
    max-width: 50%;
    padding:30px;
    margin:0 -1px;
  `}
  + li{
    background-color:#F4F8FC;
    ${BreakpointUp.lg`
      padding:50px 30px;
    `}
  }
  & h2{
    margin-bottom:5px;
  }
  & p{
    margin-bottom:30px;
  }  
`
const AboutHeading = styled.div`
  display:flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom:20px;
  .icon{
    + h2{
      margin-left:20px;      
    }
  }
  h2{
    margin-left:0;
  }
`
const AboutBody = styled.div`
  p{
    margin-bottom:0;
    + p{
    margin-top:15px;
    }
  }
`
const ValuePrinciple = styled.div`
  position: relative;
  width: 100%;
  & p{
    margin-bottom:30px;
    ${BreakpointUp.lg`
      margin-bottom:60px;
    `}
  }
`
const AboutValue = styled.div`
  width: 100%;		
  display: flex;  
  flex-wrap: wrap;
  ${BreakpointUp.sm`
    margin-right: -15px;
    margin-left: -15px;
  `}
`
const AboutValueItem = styled.div`  
  position: relative;
  display:flex;
  flex-wrap: nowrap;
  margin-bottom:20px;
  flex-direction:column;
  ${BreakpointUp.sm`
    padding: 0 15px;
    flex-direction:row;
  `} 
  ${BreakpointUp.lg`
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom:40px;
  `}
  & .icon{		
    width:74px;
    height:64px;
    min-width: 74px;
    background-color:#fff;	   
    box-shadow: 0 0 10px rgba(27,93,136,0.16);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom:15px;
    ${BreakpointUp.sm`
      margin-bottom:0;
    `}     
    + .text{
      ${BreakpointUp.sm`
        margin-left:20px;
      `}
    }
  }
  & .text{    
    & h3{
      color:#002337;
      font-weight:700;
      font-size:20px;
      line-height: 30px;
      font-family: 'Roboto', serif;
      margin:0;
    }
    & p{
      margin-bottom:0;
    }
  }
`
const ProductReviewGrid = styled.div`
  background-color:#fff;
  border:1px solid #E5E5E5;
  padding:25px;
  position: absolute;
  top:calc(100% + 10px);
  left: 0;
  right: 0;
  z-index: 1;
  box-shadow: 0 8px 20px rgba(0,0,0,0.1);
  &:before, &:after{
    content:'';
    position:absolute;
  }
  &:before{
    top:0;
    left:0;
    width: 0;
    height: 0;
    border-top: 36px solid #F99207;
    border-right: 36px solid transparent;
  }
  &:after{
    bottom:0;
    right:0;
    width: 0;
    height: 0;
    border-bottom: 36px solid #F99207;
    border-left: 36px solid transparent;
  }
`
const ProductReview = styled.ul`
	padding:0;
	margin-bottom:0;
	width:100%;
	list-style:none;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;	
	margin-right: -5px;
  margin-left: -5px;
	${BreakpointUp.sm`	
		margin-right: -15px;
		margin-left: -15px;
	`}
    
`
const ProductReviewItem = styled.li`
	margin:0;
	padding:5px;
	text-align:center;
	flex-basis: 0;
  flex-grow: 1;
	max-width: 100%;
	${BreakpointUp.sm`	
		padding:5px 15px;
	`}
	+ li{
		border-left:1px solid #E5E5E5;
	}
	& .number{
		display:block;
		font-family: 'Fira Sans', serif;
		color:#002337;
		margin-bottom: 2px;
		font-weight: 700;
		font-size:28px;
		line-height: 40px;
		${BreakpointUp.sm`	
			font-size:32px;
			line-height: 44px;
		`}
		${BreakpointUp.lg`	
			font-size:42px;
			line-height: 54px;
		`}
	}
	& .text{
		color:#959EA5;
		font-weight:500;
		display: block;
		text-align:center;
		max-width: 118px;
		width: 100%;
		margin: 0 auto;
		font-size: 14px;
		line-height: 20px;
		${BreakpointUp.sm`	
			font-size: 16px;
			line-height: 22px;
		`}
		${BreakpointUp.lg`	
			font-size:18px;
			line-height: 26px;
			max-width: none;
		`}
	}
`
const AboutUsPage = ({data}) => {
  const pageData = data.contentfulAboutUsPage
  const PostContent = HTMLContent || Content;
  return(
    <Layout>
      <SEO title="Get to Know About Mr.Carports - Buildings, Carports, Barns and Garages" description="Mr.Carports is America's leading carport dealer. We offer the best quality metal buildings at a great price with the best customer service."/>                   
      <SectionHeader pt="140px" pb="90px" xpt="110px" xpb="60px" bgColor="#F4F8FC">
        <div className="container">
          <SectionCover>
            <CoverContent>
              <BreadCrumb><Link to='/'>Home</Link>/<span>About Us</span></BreadCrumb>   
              <SectionPageTitle mt="30px" mb="20px" textAlign="left">
                {pageData.heroTitle}
              </SectionPageTitle>
              <SectionDescription mb="0" textAlign="left">
                <PostContent
                  content={generateHTML(pageData.heroDescription.childMarkdownRemark.html)}
                />
              </SectionDescription>
            </CoverContent>  
            <CoverFigure>
              <CoverImg>
                <Img fluid={pageData.heroImage.fluid} /> 
              </CoverImg>
            </CoverFigure>
          </SectionCover>
          <ProductReviewGrid>
            <ProductReview>
              <ProductReviewItem>
                <span className="number">200+</span>
                <span className="text">Successful Products</span>
              </ProductReviewItem>
              <ProductReviewItem>
                <span className="number">100%</span>
                <span className="text">Quality Products</span>
              </ProductReviewItem>
              <ProductReviewItem>
                <span className="number">100+</span>
                <span className="text">Customer Satisfaction</span>
              </ProductReviewItem>
            </ProductReview>
          </ProductReviewGrid>
        </div>
      </SectionHeader>
      <Section pt="150px" pb="90px" xpt="150px" xpb="60px">
        <div className="container">
          <AboutGrid>
            <AboutItemGrid>
              <AboutHeading>
                <div className="icon"><MissionIcon /></div>
                <h2>Our Mission</h2> 
              </AboutHeading>
              <AboutBody>
                <p>{pageData.ourMission.ourMission}</p>
              </AboutBody>
            </AboutItemGrid>
            <AboutItemGrid>
              <AboutHeading>
                <div className="icon"><VisionIcon /></div>
                <h2>Our Vision</h2> 
              </AboutHeading>
              <AboutBody>
                <p>{pageData.ourVission.ourVission}</p>
              </AboutBody>
            </AboutItemGrid>
          </AboutGrid>
          <ValuePrinciple>
            <SectionTitle textAlign="center">Our Values And Principles</SectionTitle> 
            <SectionLead textAlign="center">{pageData.valuesPrinciplesDescription.valuesPrinciplesDescription}</SectionLead>
            <AboutValue>
              {
                pageData.valuesAndPrinciples.map(item=>{
                  return(
                    <AboutValueItem key={v4()}>
                      <div className="icon">
                        <img src={item.icon} alt={item.value} />
                      </div>
                      <div className="text">
                        <h3>{item.value}</h3>
                        <p>{item.description}</p>
                      </div>
                    </AboutValueItem>
                  )
                })
              }
            </AboutValue>         
          </ValuePrinciple>

        </div>
      </Section>
      <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bg="#F4F8FC">
        <div className="container">
          <MainPanel>
              <MarkdownContent  pl="15px" pr="15px" maxWidth="1120px">
                <PostContent
                  content={generateHTML(pageData.content.childMarkdownRemark.html)}
                />
              </MarkdownContent>         
          </MainPanel>
        </div>
      </Section>
    </Layout>
  )
} 

export default AboutUsPage

export const pageQuery = graphql`
  query AboutUsPageQuery {
    contentfulAboutUsPage {
      metaTitle
      metaDescription
      heroTitle
      heroDescription {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        fluid {
          aspectRatio
          base64
          src
          srcSet
          srcSetWebp
          srcWebp
        }
      }
      ourMission {
        ourMission
      }
      ourVission {
        ourVission
      }
      valuesPrinciplesDescription {
        valuesPrinciplesDescription
      }
      valuesAndPrinciples {
        value
        description
        icon
      }
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`